import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  getCheckoutQuote,
  getPaymentLinkValidate,
  getAsset,
} from '../../api/gateway';
import {
  Asset,
  PaymentLinkValidateInterface,
  Quote,
} from '../../helpers/types';
import SelectAsset from '../../components/SelectAsset';
import { Tooltip } from '@mui/material';

import './CheckoutCrypto.scss';
import { PageLoader } from '../../components/PageLoader/PageLoader';

const initialAsset = {
  id: '',
  name: '',
  symbol: '',
  type: '',
  active: true,
  small_image_url: '',
  medium_image_url: '',
  large_image_url: '',
  networks: [],
};

const CheckoutCrypto = () => {
  const [network, setNetwork] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [quote, setQuote] = useState<Quote>();
  const [assets, setAssets] = useState<Asset[]>([]);
  const [currentAsset, setCurrentAsset] = useState<Asset>(initialAsset);
  const [payment, setPayment] = useState<PaymentLinkValidateInterface>();
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (!token) return;
      setLoading(true);
      await getPaymentLinkValidate(token)
        .then(({ data }) => {
          setPayment(data);
        })
        .catch(() => {
          navigate('/checkout-error');
        });

      await getAsset()
        .then(async ({ data }) => {
          setAssets(data.assets);
          setCurrentAsset(data.assets[0]);
          await getCheckoutQuote(
            token,
            data.assets[0].symbol,
            data.assets[0].networks[0].name,
          )
            .then(({ data }) => {
              setQuote(data);
            })
            .catch(console.log);
        })
        .catch(console.log);
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (!currentAsset) return;
    const symbol = currentAsset.symbol;
    const name = currentAsset.networks[0]?.name;
    const interval = setInterval(() => {
      getCheckoutQuotes(symbol, name);
    }, 20000);
    return () => clearInterval(interval);
  }, [JSON.stringify(currentAsset)]);

  const getCheckoutQuotes = async (sourceAssets: string, network: string) => {
    if (!token) return;
    setLoading(true);

    await getCheckoutQuote(token, sourceAssets, network).then(({ data }) => {
      setQuote(data);
    });
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      if (!currentAsset.id) return;
      getCheckoutQuotes(currentAsset.symbol, currentAsset.networks[0].name);
    })();
  }, [currentAsset]);

  return (
    <div className="container">
      <div className="checkout">
        <div className="checkout__crypto">
          <div className="checkout__crypto-title">Choose crypto</div>
          <div className="checkout__crypto-amount">
            {payment?.target_asset} {payment?.target_asset_amount.toFixed(2)}
          </div>
          <p>{payment?.description}</p>
          <div className="checkout__crypto-box">
            <section>
              <span>Pay With:</span>
              <SelectAsset
                assets={assets}
                onChange={setCurrentAsset}
                value={currentAsset}
              />
            </section>
            <section>
              <span>Network:</span>
              <main>
                {currentAsset.networks.map((v, index) => {
                  return (
                    <span
                      key={index}
                      className={network === v.name ? 'active' : ''}
                      onClick={() => setNetwork(v.name)}
                    >
                      {v.name}
                    </span>
                  );
                })}
              </main>
            </section>
            <div className="checkout__crypto-approx">
              <span>
                Approximate amount:{' '}
                <Tooltip title="This quote is approximate and is updated every 20 seconds until the next step">
                  <div>?</div>
                </Tooltip>{' '}
              </span>
              {!quote ? (
                <PageLoader></PageLoader>
              ) : (
                <div className="checkout__crypto-approx-amount">
                  <img
                    src={quote?.asset.medium_image_url}
                    alt={quote?.asset.name}
                  />
                  {quote?.source_amount_estimate}
                </div>
              )}
            </div>
            <div className="checkout__crypto-box-action">
              <div></div>
              <button
                disabled={!currentAsset.id || loading}
                onClick={() => navigate(`/checkout-review/${token}`)}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutCrypto;
