import React from 'react';
import { ReactComponent as Clock } from '../../assets/clock.svg';

import './CheckoutProcess.scss';

const CheckoutProcess = () => {
  return (
    <div className="container">
      <div className="checkout">
        <div className="checkout__process">
          <div className="checkout__process-title">Checkout</div>
          <Clock />
          <div className="checkout__process-header">
            We are processing your transaction
          </div>
          <p>
            Thank you for using Beta.
            <br /> Your payment is being processed and we will send an e-mail
            confirmation once the deposit has been received.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CheckoutProcess;
