import React from 'react'
import { Route, Routes } from "react-router-dom";
import CheckoutError from "./containers/CheckoutError/CheckoutError";
import CheckoutInstructions from "./containers/CheckoutInstructions/CheckoutInstructions";
import CheckoutReview from "./containers/CheckoutReview/CheckoutReview";
import CheckoutProcess from "./containers/CheckoutProcess/CheckoutProcess";
import CheckoutDeposit from "./containers/CheckoutDeposit/CheckoutDeposit";
import CheckoutCrypto from "./containers/CheckoutCrypto/CheckoutCrypto";
import Refund from './containers/Refund/Refund';

import "./App.scss";

function App() {
  return (
    <Routes>
      {/* Checkout pages */}
      <Route path="/checkout-error" element={<CheckoutError />} />
      <Route path="/checkout-instructions/:token" element={<CheckoutInstructions />} />
      <Route path="/checkout-crypto/:token" element={<CheckoutCrypto />} />
      <Route path="/checkout-review/:token" element={<CheckoutReview />} />
      <Route path="/checkout-deposit/:token" element={<CheckoutDeposit />} />
      <Route path="/checkout-process" element={<CheckoutProcess />} />
      <Route path="/refund/:token" element={<Refund />} />
      <Route path="/topup/:token" element={<CheckoutDeposit />} />
    </Routes>
  );
}

export default App;
