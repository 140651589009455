import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { postRefundOrderRequest } from '../../api/gateway';
import { ReactComponent as Clock } from '../../assets/clock.svg';
import { PageLoader } from '../../components/PageLoader/PageLoader';

import './Refund.scss';

const Refund = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("We received your refund request");
  const [message, setMessage] = useState<string>("Your refund will be processed in the next few hours.");

  useEffect(() => {
    (async () => {
      if (!token) return;
      setLoading(true);
      await postRefundOrderRequest(token)
        .then(() => {
          setLoading(false);
        })
        .catch((error) => {
          if (error.response && error.response.status === 403) {
            setTitle("Insufficient Balance for Refund")
            setMessage(error.response.message)
          } else {
            navigate('/checkout-error');
          }
        });
    })();
  }, [token]);

  return (
    <div className="container">
      <div className="checkout">
        <div className="refund">
          <h1 className="refund-header">Refund</h1>
          {loading ? (
            <div className="refund-loading">
              <PageLoader />
            </div>
          ) : (
            <>
              <Clock />
              <h1 className="refund-title">{title}</h1>
              <p>{message}</p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Refund;
