import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  getCheckoutQuoteReview,
  getPaymentLinkValidate,
} from '../../api/gateway';
import { QuoteReview } from '../../helpers/types';
import './CheckoutReview.scss';
import { PageLoader } from '../../components/PageLoader/PageLoader';

const CheckoutReview = () => {
  const [quote, setQuote] = useState<QuoteReview>();
  const [loading, setLoading] = useState<boolean>(false);
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (!token) return;
      setLoading(true);
      await getPaymentLinkValidate(token)
        .then(async () => {
          await getCheckoutQuoteReview(token).then(({ data }) => {
            setQuote(data);
            setLoading(false);
          });
        })
        .catch(() => {
          navigate('/checkout-error');
        });
    })();
  }, []);

  const formatValue = () => {
    const symbol = quote?.price.symbol.slice(3, quote?.price.symbol.length)
    const value = quote?.price.price

    if (!symbol || !value) return

    const symbolFormatted = symbol === 'BRL' ? 'pt-BR' : 'en-US'


    return new Intl.NumberFormat(symbolFormatted, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value)
  }

  return (
    <div className="container">
      <div className="checkout">
        <div className="checkout__review">
          <div className="checkout__review-title">
            Review deposit instruction
          </div>
          {loading ? (
            <div className='checkout__review-loading'>
              <PageLoader></PageLoader>
            </div>
          ) : (
            <>
              <div className="checkout__review-box">
                <img src={quote?.asset.large_image_url} alt="ethereum" />
                <div className="checkout__review-box-title">
                  <span>{quote?.asset.name}</span>
                  <span style={{ fontWeight: 700 }}>{quote?.asset.symbol}</span>
                </div>
                <div className="checkout__review-box-numbers">
                  <span>
                    {quote?.target_asset} {quote?.target_amount.toFixed(2)}
                  </span>
                  <span style={{ fontWeight: 700 }}>
                    {quote?.source_amount_estimate}
                  </span>
                </div>
              </div>
              <div className="checkout__review-amount">
                <div>
                  1 {quote?.price.symbol.slice(0, 3)} ={' '}
                  <span>
                    {quote?.price.symbol.slice(3, quote?.price.symbol.length)}{' '}
                    {formatValue()}
                  </span>
                </div>
                <div></div>
              </div>
            </>
          )}
          <p>
            By proceeding you declare that you have read and agree with our
            <Link
              to={'https://www.betaramps.com/terms-and-conditions'}
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms and Conditions
            </Link>{' '}
            and our
            <Link
              to={'https://www.betaramps.com/privacy-policy'}
              target="_blank"
              rel="noopener noreferrer"
            >
              Refund Policy.
            </Link>
          </p>
          <div className="checkout__review-button">
            <button
              onClick={() => {
                navigate(`/checkout-crypto/${token}`);
              }}
            >
              Return
            </button>
            <button
              onClick={() => {
                navigate(`/checkout-deposit/${token}`);
              }}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutReview;
