import Axios from 'axios';
import urlJoin from 'url-join';

const { REACT_APP_BASE_URI } = process.env;

const gatewayUrl = REACT_APP_BASE_URI as string;

export const getPaymentLinkValidate = (token: string) => {
  const url = urlJoin(gatewayUrl, `v1/paymentlink/validate/${token}`);

  return Axios.get(url);
};

export const getAsset = () => {
  const url = urlJoin(gatewayUrl, `v1/asset?type=crypto`);

  return Axios.get(url);
};

export const getCheckoutQuote = (token: string, sourceAsset: string, network: string) => {
  const url = urlJoin(gatewayUrl, `v1/checkout/quote/${token}?network=${network}&source_asset=${sourceAsset}`);

  return Axios.get(url);
};

export const getCheckoutQuoteReview = (token: string) => {
  const url = urlJoin(gatewayUrl, `v1/checkout/quote/review/${token}`);

  return Axios.get(url);
};

export const postOrderPlace = (token: string) => {
  const url = urlJoin(gatewayUrl, `v1/order/place/${token}`);

  return Axios.post(url);
};

export const postRefundOrderRequest = (token: string) => {
  const url = urlJoin(gatewayUrl, `v1/order/refund/order/request/${token}`);

  return Axios.post(url);
};

export const postTopUp = (token: string) => {
  const url = urlJoin(gatewayUrl, `v1/order/topup/${token}`);

  return Axios.post(url);
};

