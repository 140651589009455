import React, { useState, useEffect } from 'react';
import { ReactComponent as Number1 } from '../../assets/number-1-light.svg';
import { ReactComponent as Number2 } from '../../assets/number-2-light.svg';
import { ReactComponent as Number3 } from '../../assets/number-3-light.svg';
import { ReactComponent as Tick } from '../../assets/tick.svg';
import { useParams, useNavigate } from 'react-router-dom';
import { getPaymentLinkValidate } from '../../api/gateway';
import { PaymentLinkValidateInterface } from '../../helpers/types';

import './CheckoutInstructions.scss';

const CheckoutInstructions = () => {
  const [payment, setPayment] = useState<PaymentLinkValidateInterface>();
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (!token) return;
      await getPaymentLinkValidate(token)
        .then(({ data }) => {
          setPayment(data);
        })
        .catch(() => {
          navigate('/checkout-error');
        });
    })();
  }, [token]);

  const getGreetings = () => {
    const now = new Date();
    if (now.getHours() > 5 && now.getHours() <= 12) return 'Good morning';
    if (now.getHours() > 12 && now.getHours() <= 18) return 'Good afternoon';
    if (now.getHours() > 18 && now.getHours() <= 22) return 'Good evening';
    if (now.getHours() > 22 || now.getHours() <= 5) return 'Good night';
  };

  const dynamicText = () => {
    return `${payment?.account_name} sent you a payment link of${' '} ${payment?.target_asset} ${payment?.target_asset_amount.toFixed(2)} and you can pay using crypto`
  }

  return (
    <div className="container">
      <div className="checkout">
        <div className="checkout__instructions">
          <div className="checkout__instructions-title">Instructions</div>
          <p style={{ paddingTop: 23 }}>
            {getGreetings()},
          </p>
          <p style={{ paddingBottom: 30 }}>{dynamicText()}</p>
          <ul>
            <li>
              <Number1 />
              Choose the cryptocurrency and the network which you intend to
              deposit.
            </li>
            <li>
              <Number2 />
              Review the payment details before generating a wallet to make the
              deposit.
            </li>
            <li>
              <Number3 />
              Make the deposit following the instructions on the screen.
            </li>
            <li>
              <Tick />
              Your payment will be processed within a few minutes and
              you&apos;ll receive an e-mail confirmation when its done.{' '}
            </li>
          </ul>
          <div className='checkout__instructions-actions'>
            <div></div>
            <button
              disabled={!token}
              onClick={() => navigate(`/checkout-crypto/${token}`)}
            >
              Start
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutInstructions;
