import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import {
  getPaymentLinkValidate,
  postOrderPlace,
  postTopUp,
} from '../../api/gateway';
import { OrderPlace } from '../../helpers/types';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Snackbar from '@mui/material/Snackbar';
import Tooltip from '@mui/material/Tooltip';
import CountdownTimer from '../../components/CountdownTimer';

import './CheckoutDeposit.scss';

const CheckoutDeposit = () => {
  const [orderPlace, setOrderPlace] = useState<OrderPlace>();
  const [open, setOpen] = useState<boolean>(false);
  const [titleSnackBar, setTitleSnackBar] = useState<string>('');
  const [isTopUp, setIsTopUp] = useState<boolean>(false);

  const { token } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();



  useEffect(() => {
    (async () => {
      const isTopUP = pathname.includes('/topup/')
      setIsTopUp(isTopUP);

      if (!token) return;
      if (isTopUP) {
        await postTopUp(token)
          .then(({ data }) => {
            setOrderPlace(data);
          })
          .catch(() => {
            navigate('/checkout-error');
          });
      } else {
        await getPaymentLinkValidate(token)
          .then(async () => {
            await postOrderPlace(token)
              .then(({ data }) => {
                setOrderPlace(data);
              })
              .catch(() => {
                navigate('/checkout-error');
              });
          })
          .catch(() => {
            navigate('/checkout-error');
          });
      }
    })();
  }, []);

  const copyTextToClipboard = async (title: string, text: string) => {
    setTitleSnackBar(title);
    setOpen(true);
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(text);
    } else {
      document.execCommand('copy', true, text);
    }
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <div className="container">
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          <AlertTitle>Success!</AlertTitle>
          {titleSnackBar} has been copied to clipboard
        </Alert>
      </Snackbar>
      <div className="checkout">
        <div className="checkout__deposit">
          <section>
            <div className="checkout__deposit-title">
              <Tooltip title="You have this amount of time to complete the transaction. Otherwise, you'll need to start the checkout again.">
                <h3>{isTopUp ? 'Top up deposit' : 'Deposit Instruction'}</h3>
              </Tooltip>
              <span>
                {orderPlace && (
                  <Tooltip title=" This quote is valid for 20 minutes. You could be entitled to a top up or refund If the deposit is received after this timeframe.">
                  <CountdownTimer
                    limit={orderPlace?.exp_time}
                    date={orderPlace?.date_time}
                  />
                  </Tooltip>
                )}
              </span>
            </div>
            {isTopUp && (
              <p style={{ paddingTop: 7 }}>
                You had deposited an insufficient amount to process your order.
                Please make a new deposit following the instructions below to
                confirm your order:
              </p>
            )}
            <div className="checkout__deposit-box">
              <div>
                Cryptocurrency:{' '}
                <span>
                  <img
                    src={orderPlace?.asset.small_image_url}
                    alt={orderPlace?.asset.name}
                  />
                  {orderPlace?.asset.name}
                </span>
              </div>
              <div>
                Network: <span>{orderPlace?.network}</span>
              </div>
              <div>
                Amount: <span>{orderPlace?.amount}</span>
              </div>
            </div>
            {!isTopUp && (
              <p>
                Please make sure to deposit the right amount to the right
                address. Otherwise your payment will be cancelled.
              </p>
            )}
            <main>
              <div>
                <span>
                  Wallet Address:{' '}
                  <span
                    onClick={() =>
                      orderPlace?.wallet_address &&
                      copyTextToClipboard(
                        'Wallet Address',
                        orderPlace?.wallet_address,
                      )
                    }
                  >
                    Click to copy
                  </span>
                </span>
                <p>{orderPlace?.wallet_address}</p>
              </div>
              {orderPlace?.asset.symbol.toLocaleLowerCase() === 'xrp' && (
                <div>
                  <span>
                    Tag:{' '}
                    <span
                      onClick={() =>
                        copyTextToClipboard('Tag', orderPlace?.tag)
                      }
                    >
                      Click to copy
                    </span>
                  </span>
                  <p>{orderPlace?.tag}</p>
                </div>
              )}
            </main>
            <div className="checkout__deposit-button">
              {!isTopUp && (
                <button onClick={() => navigate(`/checkout-crypto/${token}`)}>
                  Return
                </button>
              )}
              <button onClick={() => navigate(`/checkout-process`)}>
                Deposit Made
              </button>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default CheckoutDeposit;
