import React from 'react';
import './Header.scss';
import { ReactComponent as Logo } from '../../assets/Logo.svg';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <header className="header">
      <Logo className="header__logo" />
      <div className="header__container">
        <div className="header__language"></div>
        <Link to="#" className="header__help">
          Help
        </Link>
      </div>
    </header>
  );
};

export default Header;
