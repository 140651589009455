import React, { FC, useState, useEffect } from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Asset } from '../../helpers/types';
import { PageLoader } from '../PageLoader/PageLoader';

import './index.scss'

type Props = {
  assets: Asset[];
  onChange: (value: Asset) => void;
  value: Asset;
};

const SelectAsset: FC<Props> = ({ assets, onChange, value }: Props) => {
  const [current, setCurrent] = useState(value?.id);

  const handleChange = (event: SelectChangeEvent) => {
    const currentAsset = assets.find(v => v.id === event.target.value) as Asset
    setCurrent(event.target.value);
    onChange(currentAsset);
  };

  useEffect(() => {
    if (value) setCurrent(value.id);
  }, [JSON.stringify(value)])
  
  return (
    <>
      {!assets ? (
        <PageLoader />
      ) : (
        <Select
          labelId="demo-simple-select-standard-label"
          id="current-crypto"
          value={current}
          onChange={handleChange}
          autoWidth
          className='select-asset'
          sx={{
            color: 'black',
            '@media only screen and (max-width: 1023px)': {
              border: 'none',
            },
            '.MuiSvgIcon-root ': {
              fill: 'white !important',
            },
            '.MuiSelect-select': {
              display: 'grid',
              gridTemplateColumns: 'max-content 1fr',
              border: '1px solid #F3F3F3',
              background: '#fff',
              height: 39.5,
              padding:'0 32px 0 14px',
            },
            '.MuiOutlinedInput-notchedOutline': {
              border: 'none',
            }
          }}
          inputProps={{
            MenuProps: {
              MenuListProps: {
                sx: {
                  backgroundColor: '#fff',
                  color: 'black',
                },
              },
            },
          }}
        >
          {assets.map((v, index) => {
            return (
              <MenuItem
                key={index}
                value={v.id}
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'max-content 1fr',
                  gap: '10px',
                  width: '200px',
                }}
              >
                <img src={v.small_image_url} alt={v.name} />
                <span>{v.name}</span>
              </MenuItem>
            );
          })}
        </Select>
      )}
    </>
  );
};

export default SelectAsset;
