import dayjs from 'dayjs';
import React, { FC, useEffect, useState } from 'react';

type Props = {
  limit: number
  date: Date
}

const CountdownTimer:FC <Props> = ({limit, date}:Props) => {
  const [time, setTime] = useState<React.ReactElement>();
  const timeLimit = dayjs(date).add(limit, 'second')

  useEffect(() => {
    const interval = setInterval(() => {
      const minuteSecond = dayjs(timeLimit.diff(dayjs(), 'milliseconds')).format('mm:ss')
      const newValue = (
        <>
          <span className="minutes">{minuteSecond}</span>
        </>
      );
      if (minuteSecond === '00:00') {
        clearInterval(interval);
        window.location.reload()
      }
      setTime(newValue);
    }, 1000);
    return () => {
      clearInterval(interval)
    }
  }, []);  

  return <p className="Timer">{time}</p>;
}

export default CountdownTimer