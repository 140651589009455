import React from "react";
import { ReactComponent as Invalid } from "../../assets/invalid.svg";

import "./CheckoutError.scss";

const CheckoutError = () => {
  return (
    <div className="container">
      <div className="checkout">
        <div className="checkout__error ">
          <div className="checkout__error-header">Invalid Link</div>
          <Invalid />
          <div className="checkout__error-title">
            This link has either expired or invalid
          </div>
          <p>
            If the error persists, please contact your seller to either generate
            a new payment link or contact our Technical team.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CheckoutError;
